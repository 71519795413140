body{
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  background-color: #9dd8fa;
  display: flex;
  justify-content: center;
  align-items: center;
}


.container{
  height: 750px !important;
  width: 750px !important;
  display: grid;
  grid-template-columns: repeat(4,1fr);
  grid-template-rows: repeat(4,1fr);
  gap:1em;
}

h1{
  text-align: center;
  margin-bottom: 1em;
}

.card{
  background-color:#fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius:5px;
transform: rotateY(180deg);
animation: 2s hideCard linear;
transition: transform 0.5s;
}

@keyframes hideCard {
  0%,70%{
    transform: rotateY(0);
  }
  100%{
    transform: rotateY(180deg);
  }
}

.card img{
  max-width: 80%;
  max-height: 80%;
  transform: scale(0);
  animation: 2s hideImage linear;
  transition: transform 0.5s;
}

@keyframes hideImage {
  0%, 70%{
    transform: scale(1);
  }
  100%{
    transform: scale(0);
  }
}

.card.active{
  transform: rotateY(0);
}

.card.wrong{
  background-color: #e73b3bd3;
}

.card.correct{
  background-color: #80e93ad3;
}

.card.active img{
  transform: scale(1);
}

.myHeader{
  margin-top: 3px;
  margin-bottom:5px;
}

#dropdown-basic{
  margin-left: 2px;
}


.myHeader img{
  max-width: 30%;
  max-height: 30%;
}

